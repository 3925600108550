<template>
  <div
    class="portal-page-background"
    :class="[
      'page-shared',
      portalCustomizations?.appearance,
      'page-floating'
    ]"
    :style="customCSSVars"
  >
    <!-- Background Image -->
    <div class="portal-page-background-image"></div>
    <!-- FORM -->
    <LoaderWebFont
      :families="[organization?.brandingFontHeading || 'Inter',
                  organization?.brandingFontParagraph || 'Inter']"
    >
      <div
        :class="[
          'content-shared',
          `content-${portalCustomizations?.pageLayout}`,
          'content-background-floating'
        ]"
      >
        <div class="flex flex-col items-center justify-center flex-1">
          <!-- <img :src="organization.logoUrl" class="mb-48 max-w-[240px] min-w-[180px]" /> -->
          <div
            v-if="organization?.logoUrl || organization?.logoDarkUrl"
            class="mb-48 max-w-[180px] min-w-[180px]"
          >
            <Logo
              :dark="organization?.logoDarkUrl"
              :light="organization?.logoUrl"
              :theme="portalCustomizations?.appearance"
            />
          </div>
          <!-- SLOT -->
          <div
            class="flex flex-col items-center
          justify-center flex-1 grow text-gray-700
           dark:text-darkGray-300"
          >
            <slot />
          </div>
          <!-- SLOT -->
        </div>
      </div>
    </LoaderWebFont>
    <FeatureFlags
      v-slot="{ portalShowPoweredBySuperOkay }"
      :flags="['portalShowPoweredBySuperOkay']"
    >
      <PoweredBySuperokay v-if="portalShowPoweredBySuperOkay" />
    </FeatureFlags>
  </div>
</template>
<script>
import { computed, defineComponent, watch } from '@vue/composition-api'
import tinycolor from 'tinycolor2'
import useBrowserTitle from '@/v2/lib/composition/useBrowserTitle'
import PoweredBySuperokay from '@/components/PoweredBySuperokay.vue'
import FeatureFlags from '@/components/FeatureFlags.vue'
import Logo from '@/components/Logo.vue'
import { optimizedImageUrl } from '@/v2/services/uploads/uploadsCompositions'
import LoaderWebFont from '@/components/LoaderWebFonts.vue';
import { useCurrentOrganization } from '@/v2/services/organizations/compositions'

export default defineComponent({
  name: 'LayoutPortalGuest',
  components: {
    PoweredBySuperokay,
    FeatureFlags,
    Logo,
    LoaderWebFont,
  },
  props: {
  },
  setup() {
    const organization = useCurrentOrganization()
    const organizationName = computed(() => organization.value?.name)
    const hasWhiteLabel = computed(() => organization.value?.featureFlags?.fullWhiteLabel)
    const portalCustomizations = computed(() => organization.value?.portalLoginCustomizations)


    const customCSSVars = computed(() => ({
      '--cp-background': portalCustomizations.value?.backgroundColor ?? '#F9FAFB',
      '--cp-background-image-url': `url(${optimizedImageUrl(portalCustomizations.value?.backgroundImage)})`,
      '--cp-background-image-opacity': portalCustomizations.value?.backgroundImageOpacity / 100,
      '--cp-button-color': portalCustomizations.value?.loginButtonColor,
      '--cp-text-color': portalCustomizations.value?.loginButtonTextColor,
      '--cp-accent': tinycolor(portalCustomizations.value?.loginButtonColor).toRgbString(),
      '--cp-accent-rgb': `
      ${tinycolor(portalCustomizations.value?.loginButtonColor).toRgb().r},
      ${tinycolor(portalCustomizations.value?.loginButtonColor).toRgb().g},
      ${tinycolor(portalCustomizations.value?.loginButtonColor).toRgb().b}`,
      '--cp-heading-font': organization.value?.brandingFontHeading || 'Inter',
      '--cp-paragraph-font': organization.value?.brandingFontParagraph || 'Inter',
    }))

    // Set page title to organization name, if it has `fullWhiteLabel`
    watch(
      hasWhiteLabel,
      newValue => newValue && useBrowserTitle(organizationName),
      { immediate: true }
    )

    return {
      // Static
      // State
      portalCustomizations,
      customCSSVars,
      organization,
      // Methods
    }
  },
})
</script>

<style lang="postcss" scoped>
.login-button {
  background-color: var(--cp-button-color);
  color: var(--cp-text-color)
}
.portal-page-background {
  background-color: var(--cp-background);
  @apply md:absolute md:inset-0 z-0;
}

.portal-page-background-image {
  background-image: var(--cp-background-image-url);
  background-size: cover;
  background-position: center center;
  opacity: var(--cp-background-image-opacity);
  @apply fixed inset-0 z-1;
}

/* ##########  */
/* PAGE CLASSES */
/* ##########  */
.page-shared {
  @apply flex items-center h-screen overflow-x-hidden;
  @apply md:overflow-y-auto px-12 md:pt-0 md:px-0;
}
.page-floating {
  @apply md:pt-12 justify-center;
}


.content-shared {
  @apply relative flex flex-col md:overflow-hidden;
  @apply z-20 rounded-xl md:rounded-none;
  @apply md:col-span-1 p-16 ;
  @apply p-32 md:p-88 md:flex-1;
  @apply md:min-w-[600px] md:max-w-[70vw];
  @apply md:min-h-vh-2/3;
}

/* ##########  */
/* FLOATING */
/* ##########  */

.content-background-floating{

}

.content-background-left,
.content-background-right {
  @apply bg-white border-gray-200;
  @apply dark:bg-darkGray-900 dark:border-darkGray-800;
  @apply dark:shadow-none dark:border-none;
  @apply bg-white border-gray-200;
  @apply border shadow rounded-xl;
}
</style>
