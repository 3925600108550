
<div
  class="portal-page-background"
  :class="[
    'page-shared',
    portalCustomizations?.appearance,
    'page-floating'
  ]"
  :style="customCSSVars"
>
  <!-- Background Image -->
  <div class="portal-page-background-image"></div>
  <!-- FORM -->
  <LoaderWebFont
    :families="[organization?.brandingFontHeading || 'Inter',
                organization?.brandingFontParagraph || 'Inter']"
  >
    <div
      :class="[
        'content-shared',
        `content-${portalCustomizations?.pageLayout}`,
        'content-background-floating'
      ]"
    >
      <div class="flex flex-col items-center justify-center flex-1">
        <!-- <img :src="organization.logoUrl" class="mb-48 max-w-[240px] min-w-[180px]" /> -->
        <div
          v-if="organization?.logoUrl || organization?.logoDarkUrl"
          class="mb-48 max-w-[180px] min-w-[180px]"
        >
          <Logo
            :dark="organization?.logoDarkUrl"
            :light="organization?.logoUrl"
            :theme="portalCustomizations?.appearance"
          />
        </div>
        <!-- SLOT -->
        <div
          class="flex flex-col items-center
        justify-center flex-1 grow text-gray-700
         dark:text-darkGray-300"
        >
          <slot />
        </div>
        <!-- SLOT -->
      </div>
    </div>
  </LoaderWebFont>
  <FeatureFlags
    v-slot="{ portalShowPoweredBySuperOkay }"
    :flags="['portalShowPoweredBySuperOkay']"
  >
    <PoweredBySuperokay v-if="portalShowPoweredBySuperOkay" />
  </FeatureFlags>
</div>
